.radio-group-with-img {
  img {
    max-height: 20px;
  }

  .ant-radio-inner {
    border-color: $color-border;

    &::after {
      background-color: black;
    }
  }
}
