.pale-label label {
  font-weight: 500;
}

.recipient-data-form {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.phone-input-wrapper {
  @media (max-width: 1024px) and (orientation: landscape) {
    margin-top: 60px;
  }
}
