$message-width-mob: 200px;
$message-width-desk: 240px;
$space-between-blocks: 10px;

$tail-space: -12px;
$tail-size: 20px;

$message-sub-info-font-size: $font-size-xs;

.message {
  @extend .border-radius-10;

  padding: 8px;
  margin-bottom: $space-between-blocks;
  margin-left: 30px;
  position: relative;

  word-break: break-word;

  max-width: $message-width-mob;
  @media (min-width: $screen-md) {
    max-width: $message-width-desk;
    width: $message-width-desk;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: $tail-space;
    width: 0;
    height: 0;
    border-top: $tail-size solid $color-bg-sms;
    border-left: $tail-size solid transparent;
  }

  &_sms {
    background: $color-bg-sms;

    &::after {
      border-top-color: $color-bg-sms;
    }
  }

  &_whatsapp {
    background: $color-bg-whatsapp;

    &::after {
      border-top-color: $color-bg-whatsapp;
    }
  }

  &_messenger {
    background: $color-bg-fb;

    &::after {
      border-top-color: $color-bg-fb;
    }
  }

  &_right {
    margin-left: auto;
    margin-right: 30px;
    background: $color-bg-message-out;
    color: white;

    &::after {
      left: auto;
      right: $tail-space;
      border-top-color: $color-bg-message-out;
      border-right: $tail-size solid transparent;
    }

    .message__date {
      color: white;
    }
  }

  &__date {
    font-size: $message-sub-info-font-size;
    font-weight: bold;
    text-align: right;
  }
}

.message-header {
  text-transform: capitalize;

  font-size: $message-sub-info-font-size;
  line-height: 22px;
  font-weight: bold;

  padding-left: 18px;
  padding-right: 20px;

  &_right {
    text-align: right;
  }

  &_sms {
    color: $color-sms;
  }

  &_whatsapp {
    color: $color-whatsapp;
  }

  &_messenger {
    color: $color-fb;
  }

  &__sign {
    color: $color-message-sign;
  }
}

.message-divider {
  .ant-divider-inner-text {
    font-size: $message-sub-info-font-size;
  }
}
