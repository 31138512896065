@import 'app/assets/styles/variables';

$gift-price-font-size: $font-size-xxl;
$gift-price-space: 30px;

.gift-price {
  @extend .bordered_bottom, .bordered_bottom_light;

  display: flex;
  align-items: center;

  @media (min-width: $screen-md) {
    width: fit-content;
  }

  &_full {
    width: auto;
  }

  &__icon {
    flex: 1;
    margin-right: $gift-price-space;
    font-size: $gift-price-font-size;

    path {
      color: black;
    }
  }
}
