.breadcrumb {
  font-size: $font-size-xl;

  &__label {
    &_inactive {
      color: $color-font-inactive;

      svg {
        fill: $color-font-inactive;
      }
    }
  }
}
