$logo-font: proxima-nova, sans-serif;
$logo-letter-spacing: -0.44px;
$mobile-top-padding: 40px;

.clp-header {
  @media (min-width: $screen-md) {
    padding-top: 35px;
  }

  .clp-horizontal-menu {
    padding-top: 0;

    @media (min-width: $screen-md) {
      padding-top: 0;
    }
  }
}

.clp-logo {
  display: flex;

  &__label {
    font-family: $logo-font;
    font-weight: 500;
    letter-spacing: $logo-letter-spacing;
    display: block;
    padding: 0;
  }

  &__sub-label {
    font-family: $logo-font;
    letter-spacing: $logo-letter-spacing;
    display: block;
  }

  &__text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

a.logo {
  display: block;
}

.ant-menu-item-active,
.ant-menu-item-open,
.ant-menu-item-selected {
  &:after {
    margin-top: 0 !important;
  }
}

.clp-sub-menu {
  padding-top: $mobile-top-padding;
}
