$clp-timepicker-border-color: #646464;
$clp-timepicker-icon-color: #646464;
$clp-timepicker-text-color: #0f0d18;

$clp-timepicker-li-color: #333447;
$clp-timepicker-li-bg: #f5f5f5;

.clp-timepicker {
  .ant-time-picker-input[disabled],
  .ant-time-picker-input[disabled] + .ant-time-picker-icon {
    opacity: 0.5;

    &:hover {
      border-color: $clp-timepicker-border-color;
    }
  }

  &_readonly {
    .ant-time-picker-input[disabled],
    .ant-time-picker-input[disabled] + .ant-time-picker-icon {
      opacity: 1;
    }
  }

  .ant-time-picker-input {
    background: transparent;
    border-color: $clp-timepicker-border-color;
    color: $clp-timepicker-text-color;
  }

  svg {
    fill: $clp-timepicker-icon-color;
  }

  .ant-time-picker-panel-input {
    background: red;
  }
}

.clp-timepicker-popup {
  .ant-time-picker-panel-input {
    color: $clp-timepicker-text-color;
  }

  .ant-time-picker-panel-select li {
    &:hover {
      background: $clp-timepicker-li-bg;
    }
    &:focus {
      color: $clp-timepicker-li-color;
    }
  }
}
