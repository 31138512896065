@import '~@axmit/clp-library/lib/assets/variables';

$close-button-minHeight: 48px;
$close-icon-paddingTop: 5px;
$padding-for-step-number: 1px;

$separated-modal-bg-left: $color-backgroundcp;
$separated-modal-bg-right: white;
$separated-modal-content-space: 300px;

.separated-modal {
  min-height: 100vh;
  background: $separated-modal-bg-left;
  padding-bottom: $spacer-8;

  @media (min-width: $screen-md) {
    padding-bottom: $spacer-16;
  }

  @media (min-width: $screen-md) {
    &:before {
      background: $separated-modal-bg-right;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
    }
  }

  &__content {
    @media (min-width: $screen-md) {
      height: calc(100vh - #{$separated-modal-content-space});
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  &__close-button {
    min-height: $close-button-minHeight;
  }

  &__close-button-icon {
    padding-top: $close-icon-paddingTop;
  }

  .ant-steps-icon {
    padding-right: $padding-for-step-number !important;
  }
}

.finalize-content {
  margin-top: 38px;
}
