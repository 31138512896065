$text-color: $color-font-dark-2;
$font-size: $font-size-sm;

$sub-label-font-size: $font-size-xs;
$sub-label-font-color: $color-font-default;

.radio-group {
  .ant-radio-wrapper {
    display: block;
    margin-bottom: 15px;

    .ant-radio-inner {
      border-color: $color-border;

      &:after {
        background-color: black;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px $color-shadow;
    }

    font-size: $font-size;
    line-height: 16px;
    color: $text-color;
  }

  &__sub-label {
    font-size: $sub-label-font-size;
    line-height: 16px;
    color: $sub-label-font-color;
    padding-left: 25px;
  }

  &_inline {
    .ant-radio-wrapper {
      display: inline-block;
    }
  }
}
