.bordered_bottom {
  border-bottom: 1px solid $color-secondary;

  &_light {
    border-color: #b6b4b4;
  }
}

.first-letter {
  &_capitalize {
    text-transform: capitalize;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.t {
  &_decoration_none {
    text-decoration: none !important;
  }

  &_spacing {
    &_lg {
      letter-spacing: 1.2px;
    }
  }

  &_bold {
    font-weight: 800;
  }


  &_center {
    text-align: center;
  }
  
  &_no-wrap {
    white-space: nowrap;
  }
}

.d-flex {
  display: flex;

  &__item {
    flex: 1;
  }

  &_align {
    &_center {
      align-items: center;
    }

    &_end {
      align-items: flex-end;
    }
  }

  &_justify {
    &_between {
      justify-content: space-between;
    }

    &_end {
      justify-content: flex-end;
    }
  }

  &_direction {
    &_column {
      flex-direction: column;
    }
  }
}

.opacity {
  &_light {
    opacity: 0.75;
  }
}

.float {
  &_r {
    float: right;
  }
}

.mob-block {
  &_center {
    @media (max-width: $screen-md - 1) {
      margin: 0 auto;
    }
  }
}

.m {
  &_right {
    margin-left: auto;
    margin-right: 0;
  }
}

.color {
  &_primary {
    color: $color-main-1;
  }

  &_default {
    color: $color-main-2;
  }

  &_light {
    color: $color-label-light;
  }

  &_success {
    color: $color-success;
  }

  &_error {
    color: $color-error;
  }
}

.show-card-button {
  @media (max-width: 767px) {
    margin-left: 12px !important;
  }
}
