@import 'app/assets/styles/variables';

$contribution-list-item-divider-color: $color-divider-shadow;

.contribution-block {
  margin-top: 50px;
  min-height: 300px;

  @media (min-width: 1500px) {
    min-height: 550px;
  }
}

.contribution-form {
  position: relative;
  padding-bottom: 10px;

  &__content-img {
    width: 100%;

    @media (min-width: 1500px) {
      transform: translateY(-35%);
    }
  }

  @media (min-width: $screen-md) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: calc(100% - 25px);
      background: $contribution-list-item-divider-color;
    }

    &_line_full {
      &::after {
        height: 100%;
      }
    }
  }

  @media (min-width: $screen-md) {
    padding-left: 15px;
  }
}

.contributor-button {
  margin-left: 16px;
  &__text {
    color: $color-font-dark;
    text-decoration: underline;
    font-weight: 100;
    letter-spacing: 1px;
  }
}

.optional-message {
  font-size: 18px;
}

.stepper-contribution {
  margin-bottom: 30px;
  margin-top: 30px;
}

.clp-horizontal-menu {
  position: relative;
  z-index: 1;
}

