.contribution-info {
  background: white;
  padding: 25px;
  width: max-content;

  &__label {
    font-weight: bold;
    font-size: $font-size-md;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color-secondary;
  }

  &__value {
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    letter-spacing: -0.45px;
    color: $color-primary;
  }
}
