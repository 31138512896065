@import 'app/assets/styles/variables';

$aside-slide-font-family: $font-family-satisfy;
$aside-slide-max-width: 50vw;

$aside-slide-wrapper-max-width: 600px;
$aside-slide-wrapper-width: 50vw;
$aside-slide-wrapper-height: 300px;
$aside-slide-wrapper-space-top: 200px;
$aside-slide-wrapper-space-right: 280px;
$aside-slide-wrapper-space-left-md: 10px;
$aside-slide-wrapper-space-left-lg: 70px;
$aside-slide-wrapper-space-bottom: 80px;

$aside-slide-letter-color: $color-primary;
$aside-slide-letter-size: 12px;
$aside-slide-wrapper-letter-space-top: 150px;
$aside-slide-wrapper-letter-height: 350px;
$aside-slide-wrapper-letter-space-right: 300px;

$aside-slide-plane-color: $color-primary;
$aside-slide-plane-size: 16px;
$aside-slide-plane-max-width: 77vw;
$aside-slide-wrapper-plane-space-top: 283px;
$aside-slide-wrapper-plane-space-right: 450px;
$aside-slide-wrapper-plane-space-left: 180px;

$aside-slide-transform: translate(0px, -50%);
$aside-slide-transform-center: translate(-50%, -50%);

.aside-slide {
  position: fixed;
  left: 0;
  max-width: $aside-slide-max-width;
  top: 50%;
  transform: $aside-slide-transform;

  &_position {
    &_center {
      left: 25%;
      transform: translate(-50%, -50%);
      max-width: 640px;
      max-height: 640px;
      @media (max-width: $screen-lg) {
        max-width: 400px;
        top: 50%;
      }
      @media (max-width: 812px) and (orientation: landscape) {
        top: 85%;
      }
    }
  }

  &_rotate {
    &_horizontal {
      transform: $aside-slide-transform rotateY(180deg);

      &.aside-slide_position_center {
        transform: $aside-slide-transform-center rotateY(180deg);
      }
    }
  }

  &_discovery {
    background-position-x: right;
    background-position-y: top;
  }

  &_plane {
    pointer-events: none;
    left: -70px;
    top: calc(50% + 20px);
    max-width: $aside-slide-plane-max-width;
    background-position-x: right !important;

    @media(max-height: 599px) {
      display: none;
    }
    @media(max-height: 715px), (min-width: 1900px) and (max-height: 750px) {
      background-size: 800px;
      top: calc(50% + 85px);
      left: -110px;
    }
    @media(max-height: 650px) {
      background-size: 610px;
      top: calc(50% + 143px);
      left: -170px;
    }
  }

  &__wrapper {
    height: $aside-slide-wrapper-height;
    width: $aside-slide-wrapper-width;
    max-width: $aside-slide-wrapper-max-width;
    overflow: hidden;
    margin-top: $aside-slide-wrapper-space-top;
    padding-right: $aside-slide-wrapper-space-right;
    margin-bottom: $aside-slide-wrapper-space-bottom;
    padding-left: $aside-slide-wrapper-space-left-md;

    @media (min-width: $screen-lg) {
      padding-left: $aside-slide-wrapper-space-left-lg;
    }
    @media (max-width: 812px) and (orientation: landscape) {
      margin-top: 0;
    }

    &_letter {
      margin-top: $aside-slide-wrapper-letter-space-top;
      height: $aside-slide-wrapper-letter-height;
      padding-right: $aside-slide-wrapper-letter-space-right;
      @media (max-width: 812px) and (orientation: landscape) {
        margin-top: 0;
      }
    }

    &_plane {
      width: $aside-slide-plane-max-width;
      max-width: $aside-slide-plane-max-width;
      padding-right: $aside-slide-wrapper-plane-space-right;
      padding-left: $aside-slide-wrapper-plane-space-left;
      margin-top: 40px;
      @media (max-width: 971px) {
        margin-top: 40px;
      }

      @media (max-height: 715px) {
        padding-right: 335px;
      }
      @media (max-height: 650px) {
        padding-right: 250px;
      }
    }
  }

  &__content {
    overflow: auto;
    height: 100%;
    @media (max-width: 1023px) {
      display: none;
    }

    &_letter {
      font-family: $aside-slide-font-family;
      font-size: $aside-slide-letter-size;
      line-height: 16px;
      color: $aside-slide-letter-color;
      white-space: pre-wrap;
    }

    &_plane {
      font-family: $aside-slide-font-family;
      font-size: $font-size-xxl;
      line-height: $aside-slide-plane-size;
      color: $aside-slide-plane-color;
      text-align: center;
      @media (min-width: $screen-lg) {
        font-size: 16px;
      }
      @media(min-width: 1680px) {
        text-align: right;
      }
    }

    &_ribbon-text {
      margin-top: 244px;

      @media(max-height: 715px) {
        margin-top: 166px;
        text-align: right;
        font-size: 14px;
      }
      @media (max-height: 650px) {
        margin-top: 115px;
      }
    }
  }
}
