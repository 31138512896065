$small-padding-threshold: 1366px;

.clp-main-menu li {
  padding: 0 10px;

  .ant-menu-submenu-title {
    padding: 0 10px;

    @media(max-width: $small-padding-threshold) {
      padding: 0 7px;
    }
  }

  @media(max-width: $small-padding-threshold) {
    padding: 0 7px;
  }
}
