$alert-modal-body-color: rgba(0, 0, 0, 0.65);
$alert-modal-height: 51px;

.alert-modal {
  .ant-modal-header {
    padding-top: $spacer-6;
    padding-bottom: $spacer-3;
  }

  .ant-modal-close {
    height: $alert-modal-height;
  }

  .ant-modal-close-x {
    height: $alert-modal-height;
    line-height: $alert-modal-height;
  }

  .ant-modal-body {
    padding-top: $spacer-5;
    padding-bottom: $spacer-3;
    color: $alert-modal-body-color;
    line-height: 22px;
    font-size: $spacer-4;
    white-space: pre-line;
  }

  .ant-modal-footer {
    padding: $spacer-4 $spacer-7 $spacer-6;
  }

  .clp-btn {
    padding-top: $spacer-3;
    padding-bottom: $spacer-3;
    height: auto;
  }
}
