.clw-table {
  .ant-table-thead {
    tr {
      th {
        border-color: $color-border-dark;
        background: $color-table-header;

        &:not(:last-child) {
          border-right: none;
        }

        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        td {
          background: transparent !important;
        }
      }

      td {
        border-color: $color-border-dark;

        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }

  &_first-col-bold {
    .ant-table-tbody {
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }

  &_last-row-bold {
    tr {
      &:last-child {
        font-weight: bold;
      }
    }
  }

  .ant-table-bordered .ant-table-body > table {
    border-color: $color-border-dark;
  }
}
