.btn-text {
  &_red, &_red span {
    color: $color-error !important;
  }

  &_small {
    height: auto !important;
    padding: 0 !important;
  }
}
