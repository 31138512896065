$bg-active: $color-primary;

.pagination-light {
  .ant-pagination-item-active {
    border-color: $bg-active;
    background: $bg-active;
    color: white;

    a {
      color: inherit;
    }
  }

  .ant-pagination-item:hover a {
    color: inherit;
  }
}
