// Do not edit directly
// Generated on Fri, 29 May 2020 09:14:55 GMT

$color-add-1: #ffec8b;
$color-add-3: #6aee5e;
$color-main-1: #0f0d18;
$color-chat-wa-bble: #c1ffb7;
$color-main-2: #646464;
$color-chat-fb-bbl: #cce6ff;
$color-backgroundcp: #fdfcf7;
$color-chat-sms-bbl: #e9e9e9;
$color-backgroundle: #f9f9f9;
$color-chat-send-bbl: #3f3f3f;
$color-add-2: #8bceff;
$color-chat-fb-color: #1991fd;
$color-chat-wa-color: #50cc66;
$effects-chatbooble-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10000000149011612);
