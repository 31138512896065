$chat-content-height: 200px;

$modal-padding: 40px;
$antd-modal-top-space: 100px;

.image-preview {
  object-fit: cover;
  width: 100%;
  max-height: $chat-content-height;

  cursor: pointer;
}

.video-preview {
  max-height: $chat-content-height;
  width: 100%;
}

.img-preview-modal {
  width: min-content !important;

  .ant-modal-body {
    padding: $modal-padding;
  }

  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }

  img {
    max-width: calc(100vw - #{$modal-padding} - #{$modal-padding});
    max-height: calc(100vh - #{$modal-padding} - #{$modal-padding} - #{$antd-modal-top-space});
  }
}
