$container-vertical-spaces: 20px;
$container-horizontal-spaces: 15px;
$container-divider-color: $color-divider;

$header-font-size: $font-size-lg;
$header-font-color: $color-font-dark;

$content-font-size: $font-size-md;
$content-font-color: $color-secondary;

$footer-divider-color: $color-divider-light;

$sign-font-size: $font-size-sm;
$sign-font-color: black;

.card-details {
  padding: $container-vertical-spaces $container-horizontal-spaces;
  word-break: break-word;

  &_bordered {
    border-bottom: 1px solid $container-divider-color;
  }

  &__header {
    font-size: $header-font-size;
    line-height: 22px;
    color: $header-font-color;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__content {
    font-size: $content-font-size;
    line-height: 22px;
    color: $content-font-color;
    padding-bottom: 10px;

    &_black {
      color: black;
    }
  }

  &__sign {
    padding-top: 5px;
    border-top: 1px solid $footer-divider-color;
    font-size: $sign-font-size;
    line-height: 22px;
    text-align: right;
    color: $sign-font-color;
  }
}
