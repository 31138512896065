$img-sign-half-space-after-content: 20px;

.img {
  &-secure {
    &-mob {
      @extend .hidden_desk !optional;
      display: block;
      max-height: 320px;
      margin: 50px auto 65px;
    }

    &-desk {
      max-height: 495px;
      margin: 50px 0 100px;
    }
  }

  &-activate {
    height: 300px;
  }
}

.keyholder-img {
  @media (min-width: 768px) {
    width: 91%;
  }
}

.img-sign {
  margin-top: 50px;
  width: calc(50vw - #{$img-sign-half-space-after-content});
}
