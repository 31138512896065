@import 'app/assets/styles/variables';

$card-gift-height: 240px;
$card-gift-font-size-sm: 14px;
$card-gift-font-size-md-mob: 18px;
$card-gift-font-size-md: 26px;
$card-gift-font-family: $font-family-default;
$card-gift-border-color: $color-border-dark;
$card-gift-label-color: $color-secondary;
$card-gift-content-color: $color-primary;

$code-color: $color-font-default-2;

$sub-label-color: $color-font-default-3;
$sub-label-size: 10px;

$priority-label-color: $color-font-light;

$label-line-height: 24px;

.card-gift {
  @extend .border-radius-2;

  border: 1px solid $card-gift-border-color;
  padding: 10px 20px 40px;
  position: relative;
  word-break: break-all;

  @media (min-width: $screen-md) {
    min-height: $card-gift-height;
  }

  &__icon {
    font-size: $font-size-md;
    line-height: inherit;
    margin-left: 5px;
    margin-top: 1px;

    path {
      color: $color-error;
    }
  }

  &__code {
    color: $code-color;
  }

  &__sublabel {
    font-size: $sub-label-size;
    line-height: 16px;
    color: $sub-label-color;
  }

  &__priority {
    @extend .border-radius-2;

    padding: 4px 10px;
    background: white;
    border: 1px solid $priority-label-color;
  }

  &__footer {
    position: absolute;
    bottom: 10px;
  }

  &__label {
    font-family: $card-gift-font-family;
    line-height: $label-line-height;
    color: $card-gift-label-color;
    word-break: break-word;

    &.ant-typography-ellipsis {
      height: $label-line-height * 2;
    }

    &_size {
      &_sm {
        font-size: $card-gift-font-size-sm;
      }

      &_md {
        font-size: $card-gift-font-size-md-mob;

        @media (min-width: $screen-md) {
          font-size: $card-gift-font-size-md;
        }
      }
    }
  }

  &__content {
    font-family: $card-gift-font-family;
    font-size: $card-gift-font-size-sm;
    line-height: 18px;
    color: $card-gift-content-color;
  }

  &__another-time-btn span{
    @media (max-width: 1490px) {
      font-size: 11px;
    }
  }
}
