$screen-md: 768px;
$screen-lg: 1024px;

$color-primary: $color-main-1;
$color-secondary: $color-main-2;
$color-success: #75c759;
$color-error: #ec4863;

$color-border: #e5e5e5;
$color-border-dark: #b6b4b4;

$color-divider: #dbdbdb;
$color-divider-light: #f2f2f2;
$color-divider-shadow: #0f0d184d;

$color-font-dark: #212121;
$color-font-dark-2: #2b2b2b;
$color-font-default: #9c9c9c;
$color-font-default-2: #8a8a8a;
$color-font-default-3: #8d8d8d;
$color-font-light: #d3d3d3;
$color-font-inactive: #c0c0c0;

$color-chats: #c6bfb2;
$color-chats-contact: #1c1d1d;
$color-sms: #404040;
$color-bg-sms: #eaeaea;
$color-whatsapp: #51cc66;
$color-bg-whatsapp: #c2ffb8;
$color-fb: $color-chat-fb-color;
$color-bg-fb: #cce7ff;
$color-bg-message-out: #404040;
$color-message-sign: #a7a7a7;

$color-table-header: #ededed;

$color-shadow: rgba(0, 0, 0, 0.08);

$color-label-light: #979797;

$border-radius-spacers: 2 10;
@include fixedSpacerBuilder(border-radius, $border-radius-spacers);

$font-size-xxl: 20px;
$font-size-xl: 18px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xs: 10px;

$font-family-default: proxima-nova, sans-serif;
$font-family-satisfy: 'Satisfy', cursive;
