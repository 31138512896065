@import 'app/assets/styles/variables';

.contributors-comment {
  padding: 15px 0;

  &__avatar {
    margin-bottom: 10px;

    @media (min-width: $screen-md) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  &__header {
    text-align: center;

    @media (min-width: $screen-md) {
      text-align: left;
    }
  }

  &__name {
    @media (max-width: $screen-md - 1) {
      display: block;
    }
  }

  &__content {
    word-break: break-word;
    padding: 15px 0;
  }
}
