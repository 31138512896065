$chat-height: 60vh;
$chat-wrapper-height: 70vh;
$chat-header-height: 52px;
$chat-input-height: 42px;
$chat-mob-info: 50px;

$chat-header-space-vertical: 20px;
$chat-header-space-horizontal: 10px;

$chat-border-color: $color-border-dark;

.chat {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  height: calc(#{$chat-wrapper-height} - #{$chat-header-height} - #{$chat-input-height});
}

.chat-scroll-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.chat-channels-desk {
  border-right: 1px solid $chat-border-color;
  height: calc(#{$chat-wrapper-height} - #{$chat-header-height} - 1px);
  overflow: auto;
}

.chat-wrapper {
  border: 1px solid $chat-border-color;
  background: white;
  min-width: 730px;

  &_fixed-height {
    height: $chat-wrapper-height;
  }

  &_mob {
    &.chat {
      height: calc(#{$chat-wrapper-height} - #{$chat-header-height} - #{$chat-input-height} - #{$chat-mob-info});
    }
  }
}

.chat-search {
  padding: $chat-header-space-vertical $chat-header-space-horizontal 0;

  &_bordered {
    border-right: 1px solid $chat-border-color;
  }
}

.chat-header {
  height: $chat-header-height;
  background: $color-chats;
  padding: 0 10px;
  vertical-align: middle;
  line-height: $chat-header-height;
  color: white;
}

.chat-input {
  border-top: 1px solid $chat-border-color;

  &_hidden input {
    display: none !important;
  }

  .ant-input-wrapper {
    height: 40px;
  }

  .ant-input {
    height: 40px;

    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }

  .ant-input-group-addon {
    border: none;
    background: transparent;

    &:first-child {
      padding: 0;
    }
  }
}

.chat-selector {
  display: flex;

  &__item {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: calc(50% + 1px);
      right: 0;
      width: 1px;
      height: $chat-input-height;
      background: $chat-border-color;
      transform: translateY(-50%);
    }

    &_disabled {
      cursor: not-allowed;

      svg {
        opacity: 0.5;
      }
    }

    &_active {
      svg path {
        fill: white;
      }

      &.chat-selector__item {
        &::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 0;
          width: 100%;
          height: 10px;
          background: $chat-border-color;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -14px;
          left: 0;
          top: auto;
          width: 100%;
          height: 10px;
          background: $chat-border-color;
        }

        &-fb {
          background: $color-fb;

          &::before,
          &::after {
            background: $color-fb;
          }
        }

        &-whatsApp {
          background: $color-whatsapp;

          &::before,
          &::after {
            background: $color-whatsapp;
          }
        }

        &-sms {
          background: $color-sms;

          &::before,
          &::after {
            background: $color-sms;
          }
        }
      }
    }

    svg {
      height: 100%;
      margin: auto;
    }
  }
}

.chat-send-icon {
  cursor: pointer;
  margin-top: 2px;

  &_disabled {
    cursor: not-allowed;

    path {
      fill: $color-sms;
      opacity: 0.5;
    }
  }

  &_messenger path {
    fill: $color-fb;
  }

  &_whatsapp path {
    fill: $color-whatsapp;
  }

  &_sms path {
    fill: $color-sms;
  }
}

.chat-wa-init {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
  width: 270px;
  position: absolute;
  bottom: 3px;
  left: 91px;
  z-index: 10;
}

.clp-btn.chat-wa-init__btn {
  background: #51CC66 !important;
  border-radius: 2px;
  border: none;
  height: 36px;
  padding: 0;
  width: 132px;

  & span {
    color: #fff !important;
  }

  .chat-wa-init__btn-lang-span {
    font-weight: 700;
    margin-left: 5px;
  }
}
