.content-wrapper {
  margin-top: 50px;
  margin-bottom: 100px;

  &_height {
    &_full {
      min-height: calc(100vh - 500px);
    }
  }

  &_width {
    &_half {
      max-width: 50%;
    }
  }
}
