$card-height-min: 60px;
$card-divider-color: $color-chats;
$card-space: 6px;

$card-badge-color: $color-sms;

$card-active-bg: $color-chats;
$card-active-color: white;

.contact-card {
  padding: $card-space;
  color: $color-chats-contact;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-height: $card-height-min;

  position: relative;

  &:first-child {
    margin-top: 15px;
  }

  &:not(:first-child) {
    &:not(.contact-card_active) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: $card-space;
        width: calc(100% - #{$card-space} - #{$card-space});
        height: 1px;
        background: $card-divider-color;
      }
    }
  }

  .contact-card_active + & {
    &::after {
      content: none !important;
    }
  }

  &_active {
    background: $card-active-bg;
    color: $card-active-color;

    .contact-card__header,
    .contact-card__message {
      color: $card-active-color;
    }

    svg {
      fill: white;
    }
  }

  &__badge {
    float: right;
    margin-top: 5px;
    margin-left: 5px;

    .ant-badge-count {
      background: $card-badge-color;
      box-shadow: none;

      p {
        color: white;
      }
    }
  }

  &__header {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .ant-skeleton-title {
    margin-bottom: 0;
  }

  .ant-skeleton-paragraph {
    margin-top: 10px !important;
  }
}
